body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-popup-content-wrapper {
  background-color: #f0f8ff;  /* Fondo azul claro */
  border-radius: 8px;  /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Sombra ligera */
  border: 1px solid #007bff;  /* Borde azul acorde al diseño */
  padding: 10px;  /* Padding más compacto */
}

.leaflet-popup-content {
  margin: 0;  /* Quitar márgenes innecesarios */
  font-family: 'Roboto', sans-serif;  /* Mantener la fuente consistente */
  color: #333;  /* Texto oscuro para mejor legibilidad */
}

.leaflet-popup-tip {
  background-color: #007bff;  /* Cambiar el color del triángulo de la punta */
}

.leaflet-popup-close-button {
  color: #333;  /* Cambiar color del botón de cerrar a oscuro */
}


.tooltip-custom {
  position: absolute;
  z-index: 9999; /* Asegúrate de que este valor sea alto */
  pointer-events: none;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -100%); /* Ajusta la posición del tooltip */
}

.container-parent {
  position: relative; /* Asegúrate de que el contenedor padre esté en posición relativa */
  z-index: 1; /* Valor bajo para que el tooltip lo sobrepase */
  overflow: visible; /* Asegúrate de que no esté ocultando el tooltip */
}
